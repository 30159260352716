.rbt-input-main {
    border: 1px solid var(--medium-border) !important;
    color: var(--medium-text);
}

#office-search .rbt-input-main {
    background-image: url(../images/magnifying_glass.png);
    background-origin: content-box;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 20px;
}

#loading-offices {
    color: var(--liberty-gray-81);
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.rbt-input-main:hover, .rbt-input-main:focus {
    box-shadow: 0 0 0 3px var(--liberty-teal);
}

.rbt-input-main:disabled,
.rbt-input-main:disabled:hover,
.rbt-input-main:disabled:focus {
    box-shadow: none;
}

.rbt-menu .dropdown-item {
    color: var(--default-text);
}

.rbt-menu .dropdown-item:hover {
    background-color: var(--liberty-teal-60);
}

.dropdown-menu {
    border: 1px solid var(--medium-border);
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}
