/* Adapted from https://designsystem.lmig.com/components/notifications/code */

.notification {
    align-items: center;
    background: var(--white);
    border: 1px solid var(--textbox-info-border);
    border-radius: 3px;
    box-sizing: border-box;
    color: var(--textbox-info-text);
    display: flex;
    left: 0;
    margin: 0 0 32px 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: opacity .1s 0s cubic-bezier(0, 0, 0.58, 1);
    z-index: 1051; /* This ensures notifications show over a modal */
}

.notification * {
    box-sizing: inherit;
}

.notification .cell {
    padding: 16px;
}

.notification .icon {
    align-self: stretch;
    background: var(--textbox-info-border);
    line-height: 0;
    padding-right: 17px;
}

.notification .icon svg, .notification .icon svg path {
    fill: var(--white);
}

.notification.error .icon .info-icon,
.notification.error .icon .success-icon,
.notification.info .icon .error-icon,
.notification.info .icon .success-icon,
.notification.success .icon .error-icon,
.notification.success .icon .info-icon {
    display: none;
}

.notification.error {
    background: var(--textbox-error-background);
    border-color: var(--textbox-error-border);
}

.notification.error .icon {
    background: var(--textbox-error-border);
}

.notification.success {
    background: var(--textbox-success-background);
    border-color: var(--textbox-success-border);
}

.notification.success .icon {
    background: var(--textbox-success-border);
}

.notification .content {
    flex-grow: 1;
    padding: 16px 16px 13px;
}

.notification .content .message {
    margin-bottom: -4px;
    margin-top: -5px;
}

.notification .dismiss {
    align-self: flex-start;
    padding: 3px;
}

.notification .dismiss button {
    align-items: center;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: block;
    flex-direction: column-reverse;
    font: inherit;
    line-height: 0;
    margin: 0;
    outline: 0;
    overflow: visible;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: auto;
}

.notification .dismiss button svg {
    background-color: transparent;
    border: 0;
    display: inline-block;
    font: inherit;
    left: calc(50% - 8px);
    line-height: 0;
    padding: 0;
    position: absolute;
    top: 14px;
}

.notification .dismiss button::before {
    background: var(--liberty-atmospheric-white);
    border-radius: 100%;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    top: 22px;
    transform: translate(-50%, -50%);
    transition: width .1s 0s cubic-bezier(0, 0, 0.58, 1), height .1s 0s cubic-bezier(0, 0, 0.58, 1), transform .1s 0s cubic-bezier(0, 0, 0.58, 1);
    width: 0;
}

.notification .dismiss button:active::before,
.notification .dismiss button:focus::before,
.notification .dismiss button:hover::before {
    height: 32px;
    transition: width .2s 0s cubic-bezier(0, 0, 0.58, 1), height .2s 0s cubic-bezier(0, 0, 0.58, 1), transform .2s 0s cubic-bezier(0, 0, 0.58, 1);
    width: 32px;
}

.notification .dismiss button::after {
    background-color: var(--white);
    border-radius: 100%;
    content: '';
    display: block;
    height: 32px;
    margin: 6px;
    width: 32px;
}