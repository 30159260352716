.drawer-control-minimize, .drawer-control-close {
    background-color: transparent;
    border: none;
    filter: none;
    outline: none !important;
}

.drawer-control-close img {
    height: 20px !important;
}

.drawer-control-minimize img {
    height: 17px !important;
}

.drawer-header, .drawer-footer {
    background-color: var(--liberty-atmospheric-white);
    margin-left: 1px;
}

.drawer-tab {
    background-color: var(--liberty-blue);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: solid 1px var(--dark-border);
    bottom: 70%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    color: var(--white);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    padding: 10px 20px;
    position: fixed;
    right: -3px;
    transform: rotate(270deg) translate(100%, -50%);
    transform-origin: right;
    z-index: 70;
}

.drawer-tab:hover {
    right: 0px;
}