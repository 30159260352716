@font-face {
  font-family: 'Guardian Sans';
  src: url('../fonts/GuardianSans-Regular.otf');
}

@font-face {
  font-family: 'Guardian Sans Light';
  src: url('../fonts/GuardianSansLight.otf');
}

:root {
  /* Standard Liberty Colors */
  --default-text: #343741;
  --medium-text: #565656;
  --light-border: #C0BFC0;
  --medium-border: #565656;
  --dark-border: #404040;
  --black: #000000;
  --white: #FFFFFF;

  /* Liberty Blues */
  --liberty-blue: #1A1446;
  --liberty-blue-90: #29254F;

  /* Liberty Teals */
  --liberty-teal: #78E1E1;
  --liberty-teal-10: #F2FCFC;
  --liberty-teal-40: #C9F3F4;
  --liberty-teal-60: #AAEDED;
  --liberty-teal-80: #99E5EA;
  --liberty-medium-teal: #28A3AF;
  --liberty-dark-teal: #06748C;

  /* Liberty Yellows */
  --liberty-yellow: #FFD000;
  --liberty-yellow-40: #FFEAA9;
  --liberty-yellow-60: #FFE280;
  --liberty-yellow-80: #FFDB50;

  /* Liberty Grays */
  --liberty-gray-4: #F5F5F5;
  --liberty-gray-10: #E6E6E6;
  --liberty-gray-29: #C0BFC0;
  --liberty-gray-69: #707070;
  --liberty-gray-81: #565656;
  --liberty-gray-90: #404040;
  --liberty-dark-gray: #343741;

  /* Liberty Notification Colors */
  --success-text: #008040;
  --error-text: #D32F2F;
  --textbox-success-background: #E1F2E6;
  --textbox-success-border: #03AC63;
  --textbox-success-text: #343741;
  --textbox-error-background: #FFF4F5;
  --textbox-error-border: #E92243;
  --textbox-error-text: #343741;
  --textbox-info-background: #FFFFFF;
  --textbox-info-border: #06748C;
  --textbox-info-text: #343741;

  /* Other Liberty Colors */
  --footer-legal: #29254f;
  --liberty-atmospheric-white: #F5F5F5;
}

* {
  box-sizing: border-box;
  font-family: 'Guardian Sans', 'Roboto', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

html, body, #root { height: 100%; }

body {
  background: var(--liberty-atmospheric-white);
  color: var(--liberty-dark-gray);
  font-family: 'Guardian Sans', 'Roboto', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

a, a:focus, a:hover, a:visited { color: var(--liberty-dark-teal); }

/* Desktop header sizes from styleguide: https://www.libertymutualbrand.com/enterprise-ux#style-responsive-type */
h2 { color: var(--liberty-blue); font-size: 40px; font-weight: bold; }
h3 { color: var(--liberty-blue); font-size: 32px; font-weight: bold; }
h4 { color: var(--liberty-blue); font-size: 24px; font-weight: bold; }

.navbar, .navbar-collapse {
  background: var(--liberty-yellow);
  color: var(--liberty-blue);
}

.navbar img {
  height: 44px;
  padding-right: 10px;
}

.navbar-toggler, .navbar-toggler:focus {
  border: none;
  outline: none;
}

.navbar-toggler-icon { height: 25px; width: 31px; }
.navbar-toggler[aria-expanded='false'] .navbar-toggler-icon { background-image: url('../images/hamburger.png'); }
.navbar-toggler[aria-expanded='true'] .navbar-toggler-icon { background-image: url('../images/hamburger_close.png'); }

#menu {
  background-color: var(--liberty-atmospheric-white);
  box-shadow: -5px 5px 5px 1px var(--light-border);
  font-size: 17px;
  position: absolute;
  right: 0;
  width: 250px;
  z-index: 11;
}

#menu .nav-item:hover {
  background-color: var(--liberty-yellow);
}

#menu .nav-item > .nav-menu {
  background-image: url('../images/caret_down.png');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 0.75em;
  color: var(--liberty-blue);
  margin-right: 1em;
}

#menu .nav-item.collapsed > .nav-menu {
  background-image: url('../images/caret_right.png');
  background-size: 0.5em;
}

#title {
  padding-left: 10px;
  vertical-align: middle;
  font-size: 28px;
  font-weight: bold;
}

footer {
  background: var(--liberty-blue);
  color: var(--white);
  padding-top: 22px;
}

footer img.liberty {
  width: 154px;
}

#social-media {
  float: right;
  padding-bottom: 16px;
}

#social-media p {
  margin-bottom: 12px;
}

#social-media img {
  height: 38px;
  padding: 0 20px 0 0;
}

#language {
  background-color: var(--footer-legal);
  padding: 16px 0;
}

table {
  width: 70%;
}

th, td {
  padding: 9px;
  text-align: left;
}

.table-header {
  background-color: var(--liberty-teal);
}

.table-wide {
 width: 80%;
}

.form {
  padding-bottom: 15px;
}

.form input[type=text], .form select, .multi-behalf-assigned, #recent-spaces {
  box-sizing: border-box;
  padding: 10px;
  margin: 0 5px;
  border-radius:5px;
  border: 1px solid var(--medium-border);
}

input[type=checkbox] {
  -webkit-appearance: none;
  background-color: var(--liberty-atmospheric-white);
  border: 1px solid var(--medium-border);
  padding: 10px;
  display: inline-block;
  border-radius: 3px;
  position: relative;
  vertical-align: middle;
}

input[type=checkbox]:checked {
  background-color: var(--liberty-gray-10);
  border: 1px solid var(--medium-border);
}

input[type=checkbox]:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 3px;
  color: var(--black);
}

input[type=text] {
  border-color: var(--light-border);
}

.form label, .multi-select-label {
  display: inline-block;
  padding: 2px;
  margin: 2px;
}

/* Button styles from styleguide main.css */
.btn {
  background-color: var(--liberty-teal);
  border: 1px solid transparent;
  border-radius: 1em;
  color: var(--liberty-blue);
  filter: drop-shadow(0 3px 0 var(--liberty-dark-teal));
  font-size: 1em;
  font-weight: 700;
  line-height: 2;
  padding: 0 1.5em;
}
.btn:hover, .btn:focus, .btn:active {
  background-color: var(--liberty-dark-teal);
  border-color: transparent;
  color: var(--white);
  filter: drop-shadow(0 3px 0 var(--liberty-blue));
}
.btn:active {
  filter: drop-shadow(0 1px 0 var(--liberty-blue));
  transform: translateY(2px);
}
.btn:disabled, .btn:disabled:hover {
  background-color: var(--liberty-gray-10);
  border-color: transparent;
  box-shadow: none;
  color: var(--medium-text);
  filter: none;
  transform: none;
}
.btn--primary {
  background-color: var(--liberty-teal);
  color: var(--liberty-blue);
  filter: drop-shadow(0 3px 0 var(--liberty-dark-teal));
}
.btn--secondary {
  background-color: var(--white);
  border-color: var(--liberty-blue);
  filter: drop-shadow(0 3px 0 var(--light-border));
}
.btn--secondary:hover, .btn--secondary:focus, .btn--secondary:active {
  border-color: transparent;
  filter: drop-shadow(0 3px 0 var(--liberty-blue));
}
.btn--secondary:active {
  filter: drop-shadow(0 1px 0 var(--liberty-blue));
  transform: translateY(2px);
}
.btn--inverse {
  background-color: var(--white);
  filter: drop-shadow(0 3px 0 var(--medium-border));
}
.btn--inverse:hover, .btn--inverse:focus {
  background-color: var(--liberty-atmospheric-white);
  color: var(--black);
  filter: drop-shadow(0 3px 0 var(--medium-border));
}
.btn--inverse:active {
  background-color: var(--default-text);
  color: var(--white);
  filter: drop-shadow(0 1px 0 var(--medium-border));
  transform: translateY(2px);
}
.btn--sm {
  font-size: 0.875em;
}
.btn--lg {
  font-size: 1.25em;
}
.btn-group .btn {
  margin-right: 1em;
}
/* End styles from main.css */

.btn--dark {
  background-color: var(--liberty-dark-teal);
  color: var(--white);
  filter: drop-shadow(0 3px 0 var(--liberty-blue));
}

.btn--dark:hover, .btn--dark:focus, .btn--dark:active {
  background-color: var(--liberty-teal);
  border-color: transparent;
  color: var(--liberty-blue);
  filter: drop-shadow(0 3px 0 var(--liberty-dark-teal));
}

.btn--dark:active {
  filter: drop-shadow(0 1px 0 var(--liberty-blue));
  transform: translateY(2px);
}

.btn-popover, .btn-popover:hover, .btn-popover:focus {
  background-color: transparent;
  box-shadow: none;
  display: inherit;
  filter: none;
  line-height: 0;
  padding: 0;
  vertical-align: top;
}

.logo-btn, .logo-btn:hover {
  background-color: transparent;
  filter: none;
  padding: 0 5px;
}

.logo-btn img {
  height: 30px;
}

#admin-home {
  text-align: center;
}

#admin-home span {
  display: inline-table;
  padding: 0 50px;
}

#admin-home img {
  height: 250px;
  padding-bottom: 25px;
}

#home, #notifications, #reserve-calendar, #reserve-floors, #reserve-space, #reserve-parking-form, #my-groups {
  display: block;
  max-width: 550px;
}

.reservation-table-toolbar {
  min-height: auto !important;
  padding-bottom: 1em;
}

.validation-error {
  color: var(--error-text);
}

.card {
  border: 1px solid var(--medium-border);
}

.card p {
  color: var(--liberty-dark-gray);
}

input[type=number] {
  width: 3em;
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button {
  margin-left: 3px;
}

#home #reserve-personal-workspace:hover,
#home #reserve-parking:hover,
#home #reservation-dashboard:hover {
  text-decoration: none;
}

#home #reserve-personal-workspace:hover .card,
#home #reserve-parking:hover .card,
#home #reservation-dashboard:hover .card,
#home #quick-reserve-button:hover {
  box-shadow: 0 0 0 3px var(--liberty-teal);
}

#home #reserve-personal-workspace[disabled],
#home #reserve-personal-workspace[disabled] .card,
#home #reserve-personal-workspace[disabled]:focus,
#home #reserve-personal-workspace[disabled]:focus .card,
#home #reserve-personal-workspace[disabled]:hover,
#home #reserve-personal-workspace[disabled]:hover .card {
  box-shadow: none;
  cursor: default;
  filter: grayscale(100%);
  border-color: var(--liberty-gray-10);
}

#home #reserve-personal-workspace[disabled] .card-text,
#home #reserve-personal-workspace[disabled] .card-title {
  color: var(--liberty-gray-81);
}

#home #reserve-personal-workspace:hover .card,
#home #quick-reserve-button:hover {
  z-index: 10;
}

.reserve-floor:hover {
  text-decoration: none;
}

.reserve-floor .disabled {
  background-color: var(--liberty-gray-10);
  cursor: default;
}

#reserve-space-legend {
  background-color: var(--liberty-gray-4);
  border: 1px solid var(--light-border);
  border-radius: 5px;
}

#reserve-space-legend .col {
  line-height: 1;
}

#reserve-space-legend .legend {
  border: 1px solid var(--medium-border);
  height: 20px;
  width: 20px;
}

#reserve-space-legend .legend.mine {
  background-color: var(--liberty-yellow);
}

#reserve-space-legend .legend.mine.accessible {
  background-color: var(--white);
}

#reserve-space-legend .legend.available {
  background-color: var(--liberty-teal);
}

#reserve-space-legend .legend.available.accessible {
  background-color: var(--liberty-gray-29);
}

#reserve-space-legend .legend.unavailable {
  background-color: var(--liberty-gray-29);
}

#reserve-space-legend .legend.unavailable.accessible {
  background-color: var(--black);
}

#reserve-space-dropdown {
  background-color: var(--liberty-gray-10);
  border: 1px solid var(--medium-border);
  border-radius: 10px;
  position: absolute;
  text-align: center;
}

h3.greeting {
  font-family: 'Guardian Sans Light';
  font-weight: normal;
  margin: 0;
}

.section-divider, .greeting {
  line-height: 30px;
  padding: 24px;
}

section {
  background-color: var(--white);
  padding: 24px;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

span.link {
  color: var(--liberty-dark-teal);
  cursor: pointer;
  font-size: 14px;
}

span.link:hover {
  text-decoration: underline;
}

/* Styling for behalf of reservation pop-up */
.popover {
  background-color: var(--liberty-gray-10);
  border: 1px solid var(--medium-border);
  border-radius: 10px;
}
.popover .arrow::before, .popover .arrow::after {
  content: none;
}
.popover-header {
  border-bottom: 1px solid var(--liberty-gray-10);
}
.popover-header, .popover-body, .popover-arrow {
  background-color: var(--liberty-gray-10);
  border-radius: 10px;
}

#reserve-on-behalf-button:disabled {
  border-color: var(--medium-border);
  filter: drop-shadow(0 3px 0 var(--light-border));
}
/* End behalf of styles */

.date-field *::-webkit-calendar-picker-indicator {
  background-image: url('../images/ireserve_logo.png');
  margin: 0;
  padding-right: 5px;
}

.date-field *::-webkit-datetime-edit-fields-wrapper {
  color: var(--liberty-blue);
}

.date-field.Mui-disabled *::-webkit-datetime-edit-fields-wrapper {
  color: var(--liberty-gray-69);
}

.date-field {
  border: 1px solid var(--light-border);
  border-radius: 5px;
  font-family: 'Guardian Sans', 'Roboto', 'Arial', sans-serif !important;
  padding-left: .25em;
}

.date-field.Mui-disabled {
  background-color: var(--liberty-gray-10);
}

select.date-field {
  padding-bottom: 5px;
  padding-top: 5px;
}

.collapse-caret, .collapse-caret:focus {
  background: none;
  border: none;
  outline: none;
}

#notifications svg {
  vertical-align: text-bottom;
}

#notifications .success-fade {
  animation: fadeOut ease 10s;
  animation-fill-mode: forwards;
}

#notifications .success-message {
  color: var(--success-text);
  vertical-align: text-bottom;
}

.offscreen-label {
  /*clipPath and the position CSS put the label off-screen while still making it readable on a screen reader*/
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  position: absolute;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#quick-reserve-button {
  background-color: var(--liberty-dark-teal);
  border: solid 1px var(--medium-border);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: var(--white);
  cursor: pointer;
  margin-top: -3px;
  padding-bottom: 6px;
  padding-top: 5px;
  position: relative;
  text-align: center;
}

#quick-reserve-button:hover {
  border-radius: 5px;
}

#quick-reserve-modal .border-fix {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.accessibility-icon, .accessibility-icon-active {
  height: 35px;
  padding: 4px 8px;
}

.accessibility-icon-active {
  filter: grayscale(100%) invert(1);
}

.circle-button {
  padding: 0;
  border-radius: 50%;
}

.nav-tabs {
  margin-bottom: 1rem;
}

/* Extra-small devices (portrait phones) */
@media (max-width: 576px) {
  /* Mobile header sizes from styleguide: https://www.libertymutualbrand.com/enterprise-ux#style-responsive-type */
  h2 { font-size: 36px; }
  h3 { font-size: 28px; }
  h4 { font-size: 20px; }
  .lead { font-size: 18px; }

  .navbar img {
    height: 36px;
  }

  .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
    height: 12px;
    width: 12px;
  }

  #social-media {
    float: left;
    padding: 15px 0 16px 30px;
  }

  #reserve-actions #cancel-reserve-button {
    display: none;
  }

  #reserve-actions #cancel-reserve-link {
    background-color: transparent;
    border: none;
    color: var(--liberty-dark-teal);
  }

  .date-field {
    max-width: 120px;
  }

  #reserve-space-legend .legend {
    height: 25px;
    width: 25px;
  }

  .accessibility-icon {
    height: 45px;
  }
}
/* Small devices (landscape phones): max-width 768px */
/* Medium devices (tablets): max-width 992px */
/* Large devices (desktops): max-width 1200px */
/* Extra-large devices (large desktops): min-width 1200px */