/* Adapted from https://designsystem.lmig.com/components/toggles/code */

/* Outer container definition */
.toggle {
    margin-right: 15px;
    position: relative;
    width: 48px;
    height: 24px;
}

/* Hide the checkbox that is required for toggle */
.toggle input {
    height: 0;
    opacity: 0;
    width: 0;
}

/* This styles the 'off' position for the slider */
.toggle .slider {
    background-color: var(--liberty-gray-10);
    border-radius: 48px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

/* This styles the 'off' position for the slider circle */
.toggle .slider:before {
    background-color: var(--white);
    border: 2px solid var(--liberty-gray-69);
    border-radius: 50%;
    bottom: 1px;
    content: '';
    height: 22px;
    left: 1px;
    position: absolute;
    transition: .4s;
    width: 22px;
}

/* Styles for the slider in the 'on' position */
.toggle input:checked + .slider {
    background-color: var(--liberty-medium-teal);
}

/* Styles for the slider circle in the 'on' position */
.toggle input:checked + .slider:before {
    border: 2px solid var(--liberty-dark-teal);
    transform: translateX(25px);
}

/* Change cursor on slider when disabled */
.toggle input:disabled + .slider {
    cursor: default;
}

/* Styles for the slider when disabled and in the 'on' position */
.toggle input:checked:disabled + .slider {
    background-color: var(--liberty-gray-29);
}

/* Styles for the slider circle when disabled and in the 'on' position */
.toggle input:checked:disabled + .slider:before {
    border: 2px solid var(--liberty-gray-69);
}