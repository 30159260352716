/* Styles from react-calendar/dist/Calendar.css */

.react-calendar {
    background-color: var(--liberty-atmospheric-white);
    border: 1px solid var(--medium-border);
}

.react-calendar button {
    color: var(--default-text);
}

.react-calendar__navigation {
    background-color: var(--liberty-yellow);
    margin-bottom: 0;
}

.react-calendar__navigation button {
    color: var(--liberty-blue);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--liberty-yellow);
}

.react-calendar__navigation button[disabled] {
    background-color: var(--liberty-yellow);
    background-image: none !important;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 16px !important;
}

.react-calendar__navigation__next-button {
    background-image: url('../../images/caret_right.png') !important;
}

.react-calendar__navigation__prev-button {
    background-image: url('../../images/caret_left.png') !important;
}

.react-calendar__month-view__weekdays {
    background-color: var(--liberty-gray-10);
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

button.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--liberty-gray-69);
}

.react-calendar__tile:disabled {
    color: var(--liberty-gray-29);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background-color: var(--liberty-teal-40);
}

.react-calendar__tile--now {
    background-color: transparent;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background-color: var(--liberty-teal-80);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: var(--liberty-gray-10);
}