/* Adapted from https://designsystem.lmig.com/components/form/alphanumeric/code */

.textbox-wrapper {
    border: none;
    box-sizing: border-box;
    margin: 0 0 8px 0;
    padding: 0;
    position: relative;
    transition: all 250ms ease-out;
    width: 100%;
}

.textbox-wrapper * {
    box-sizing: inherit;
}

.textbox-wrapper .textbox-area {
    position: relative;
}

.textbox-wrapper .textbox-input {
    appearance: none;
    background-color: var(--white);
    border: solid 1px var(--medium-border);
    border-radius: 3px;
    color: var(--default-text);
    cursor: pointer;
    font-size: 1rem;
    height: 50px;
    left: 1px;
    outline: none;
    padding: 1em 0.375em 0.8125em;
    text-overflow: ellipsis;
    transform: translateZ(0);
    transition: opacity .1s 0s cubic-bezier(0, 0, 0.58, 1);
    width: 100%;
}

.textbox-wrapper.is-populated .textbox-input {
    padding: 1.5em 0.5em 0.3125em;
}

.textbox-wrapper.is-focused .textbox-input {
    color: var(--medium-text);
    padding: 1.4375em 0.5em 0.25em;
    left: 0;
}

.textbox-wrapper.is-error .textbox-input:focus,
.textbox-wrapper.is-error.is-focused .textbox-input,
.textbox-wrapper.is-populated .textbox-input:focus,
.textbox-wrapper.is-populated.is-focused .textbox-input {
    padding: 1.4375em 0.4375em 0.25em;
}

.textbox-wrapper .textbox-input:hover:not(:focus) {
    border: solid 1px var(--liberty-medium-teal);
}

.textbox-wrapper .textbox-input:focus,
.textbox-wrapper.is-focused .textbox-input {
    border: solid 2px var(--liberty-medium-teal);
    padding: 0.9375em 0.4375em 0.75em;
}

.textbox-wrapper.is-disabled .textbox-input, .textbox-wrapper.is-read-only .textbox-input {
    background-color: var(--liberty-gray-10);
    cursor: default;
}

.textbox-wrapper.is-disabled .textbox-input:hover, .textbox-wrapper.is-read-only .textbox-input:hover {
    border: solid 1px var(--medium-border);
}

.textbox-wrapper.is-error .textbox-input {
    background-color: var(--textbox-error-background);
    border: solid 1px var(--textbox-error-border);
}

.textbox-wrapper.is-error .textbox-input:focus {
    border-width: 2px;
}

.textbox-wrapper .textbox-input::placeholder {
    color: var(--default-text);
    opacity: 1;
}

.textbox-wrapper.is-focused .textbox-input,
.textbox-wrapper .textbox-input:focus {
    padding: 1.4375em 0.4375em 0.25em;
}

.textbox-wrapper .textbox-label {
    color: var(--liberty-gray-69);
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    left: 8px;
    line-height: 1.2em;
    margin-top: -2px;
    max-width: 100%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 8px;
    text-overflow: ellipsis;
    top: 8px;
    transform: translateY(10px);
    transform-origin: 0;
    transition: all .1s 0s cubic-bezier(0, 0, 0.58, 1);
    white-space: nowrap;
    z-index: 2;
}

.textbox-wrapper.is-focused .textbox-label,
.textbox-wrapper.is-populated .textbox-label {
    font-size: .75rem;
    font-weight: 400;
    height: auto;
    transform: translateY(0);
    transition: all .2s 0s cubic-bezier(0, 0, 0.58, 1);
}

.textbox-wrapper.is-error .textbox-error {
    color: var(--error-text);
    font-size: .75rem;
    height: 22px;
}

.textbox-wrapper.is-error .textbox-label {
    top: 30px;
}

/* From Bootstrap's _close.scss */
.clear {
    color: #000;
    float: right;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    opacity: .5;
    text-shadow: 0 1px 0 #fff;
}

span.clear {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
}

a.clear.disabled {
   pointer-events: none;
}

/* From react-bootstrap-typeahead's Typeahead.scss */
.rbt-div {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 50px;
    justify-content: center;
    pointer-events: none; /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
}

.rbt-div .rbt-clear {
    margin-top: -4px;
    pointer-events: auto; /* Override pointer-events: none; above */
}

.rbt-clear {
    z-index: 1;
}